<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ $t("gift.management") }}</li>
            <li>{{ $t("gift.request") }}</li>
          </ul>
        </nav>
      </div>
      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left">
            <span> {{ $t("gift.request") }} </span>
          </h2>
        </div>
      </div>
      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="card">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <div class="row" style="width: 100%">
              <div class=" col-md-1  search-icon ">
                <i class="uil-search"></i>
              </div>
              <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                <input class="uk-search-input shadow-0 uk-form-small " type="search" v-model="filter.search"
                  :placeholder="$t('general.search')" />
              </div>
            </div>
            <div class="d-flex">
              <!-- Shorting -->
              <a href="#" class="btn btn-icon btn-hover btn-circle" :uk-tooltip="$t('general.sort_list')" title=""
                aria-expanded="false">
                <i class="uil-list-ui-alt"></i>
              </a>
              <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                <ul class="uk-nav uk-dropdown-nav">
                  <li @click="sortOrder('name', 'asc')">
                    <a href="javascript:void(0)">{{ $t("sorting.a_z") }}</a>
                  </li>
                  <li @click="sortOrder('name', 'desc')">
                    <a href="javascript:void(0)">{{ $t("sorting.z_a") }}</a>
                  </li>
                  <li @click="sortOrder('point', 'asc')">
                    <a href="javascript:void(0)">
                      {{ $t("gift.filter.highest_point") }}</a>
                  </li>
                  <li @click="sortOrder('point', 'desc')">
                    <a href="javascript:void(0)">
                      {{ $t("gift.filter.lowest_point") }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{ $t("users.user") }}</th>
                <th scope="col">{{ $t("gift.name") }}</th>
                <th scope="col">{{ $t("point.coin") }}</th>
                <th scope="col">{{ $t("general.added_at") }}</th>
                <th scope="col">Stok miktarı</th>
                <th scope="col">{{ $t("general.status") }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="list">
              <!--user item-->
              <tr v-for="item in formattedGiftHistories">
                <th scope="row">
                  <div class="media align-items-center">
                    <div>
                      <div class="avatar-parent-child" style="width: max-content">
                        <img alt="Image placeholder" :src="item.user_image" class="avatar rounded-circle" />
                        <span class="avatar-child avatar-badge bg-success"></span>
                      </div>
                    </div>
                    <div class="media-body ml-4">
                      <a class="name h6 mb-0 text-sm disabled">{{
                        item.user_name
                      }}</a>
                      <small class="d-block font-weight-bold">#{{ item.user_id }}</small>
                    </div>
                  </div>
                </th>
                <th scope="row">
                  <div class="media align-items-center">
                    <div>
                      <div class="avatar-parent-child">
                        <img :src="item.image" class="avatar" />
                      </div>
                    </div>
                    <div class="media-body ml-4">
                      <!-- <router-link
                      :to="{ name: 'company.gift.add' }"
                      class="name h6 mb-0 text-sm"
                    >
                      {{ item.name }}
                    </router-link> -->
                      {{ item.name }}
                    </div>
                  </div>
                </th>
                <td>{{ item.point }}</td>
                <td>{{ item.created_at }}</td>
                <td style="text-align: center;">{{ item.last_quantity }}</td>
                <td v-if="item.status_id === 0">{{ 'reddedildi' }}</td>
                <td v-if="item.status_id === 1">{{ 'beklemede' }}</td>
                <td v-if="item.status_id === 2">{{ 'onaylandı' }}</td>
                <td class="text-right">
                  <div class="actions ml-3">
                    <a v-if="item.gift_address_required" class="btn btn-icon btn-hover btn-sm btn-circle"
                      uk-toggle="target: #giftRequestDetails" @click="setModal(item.address)" uk-tooltip="Detay">
                      <i class="uil-info-circle text-info"></i>
                    </a>
                    <a href="javascript:void(0)" class="btn btn-icon btn-hover btn-sm btn-circle"
                      :class="{ 'disabled': (item.status_id === 2 || item.last_quantity <= 0) }"
                      :uk-tooltip="$t('general.approve')" @click="setStatus(item.id, 2)">
                      <i class="uil-check-circle text-success"></i>
                    </a>
                    <a href="javascript:void(0)" class="btn btn-icon btn-hover btn-sm btn-circle"
                      :class="{ 'disabled': item.status_id === 0 }" :uk-tooltip="$t('general.reject')"
                      @click="setStatus(item.id, 0)" aria-expanded="false">
                      <i class="uil-minus-circle text-danger"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <pagination v-if="pagination" :pagination="pagination" @changePage="setPage($event)"></pagination>
    </div>
    <div id="giftRequestDetails" class="uk-modal" uk-modal>
      <div class="uk-modal-dialog bggray">
        <div class="uk-padding-small" uk-grid>
          <h4>Hediye Talebi Detayları</h4>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group row mb-3">
                <label class="col-md-4 col-form-label" for="name">Kazanıldığı eğitim</label>
                <div class="col-md-8">
                  <span id="name"> İş Güvenliği ve Sağlığı Eğitimi</span>
                </div>
              </div>

              <div class="form-group row mb-3">
                <label class="col-md-4 col-form-label" for="address">Adres</label>
                <div class="col-md-8">
                  <span id="address">
                    Esentepe, Onur İş Merkezi, Müselles Sokak, Büyükdere Cd.
                    No:1, 34394 Şişli
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right bggray">
          <button class="uk-button uk-button-default small uk-modal-close" type="button">
            <i class="uil-times"></i> Kapat
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";
import moment from "moment";
import Pagination from "@/assets/components/Pagination";

import history_module, {
  BASE_URL,
  MODULE_NAME,
  ITEMS,
  GET_ITEMS,
  UPDATE_ITEM,
  ERROR,
} from "@/core/services/store/gift_history.module";

const _HISTORY_MODULE_NAME = MODULE_NAME;

export default {
  name: "GiftList",
  components: { DataTable, Pagination },
  data() {
    return {
      // status_id: 1,
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 10,
      filter: {
        search: null,
        orderBy: "created_at",
        sort: "desc",
      },
      headers: this.apiHeaders,
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_HISTORY_MODULE_NAME, history_module);
  },
  computed: {
    gift_history: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) {
      },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
    formattedGiftHistories() {
      let results = [];
      if (this.gift_history === null || !("data" in this.gift_history))
        return [];
      this.pagination = this.gift_history

      this.gift_history.data.forEach((currentItem) => {
        if (currentItem.user === undefined) return []
        results.push({
          user_name: currentItem.user?.name + " " + currentItem.user?.surname,
          user_image: currentItem.user?.photo_link,
          user_id: currentItem.user?.id,
          name: currentItem.name,
          image: currentItem.gift?.image_link,
          gift_address_required: currentItem.gift?.address_required,
          id: currentItem.id,
          point: currentItem.point,
          address: currentItem.address,
          created_at: currentItem.created_at
            ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
          last_quantity: currentItem.gift?.stock_quantity > 0 ? currentItem.gift?.stock_quantity
            : 0,
          status: this.getStatusById(currentItem.status_id),
          status_id: currentItem.status_id,
        });
      });

      return results;
    },
  },
  methods: {
    getGiftHistoryResult() {
      let self = this;
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          like: self.filter.search,
          page: self.page,
          per_page: self.per_page,
          sort: self.filter.sort,
          order_by: self.filter.orderBy,
          status_id: self.status_id,
          with_user: true,
        },
      });
    },
    setModal(address) {
      const addressSpan = $('#giftRequestDetails span#address');
      addressSpan.html(address);
    },
    getStatusById(id) {
      switch (id) {
        case 0:
          return this.$t("general.passive");
        case 1:
          return this.$t("general.active");
        case 2:
          return this.$t("general.approved");
        default:
          return "";
      }
    },
    setStatus(id, status_id) {
      this.$store.dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
        url: BASE_URL + "/" + id + "/update-status",
        contents: {
          status_id: status_id
        }
      }).then(() => {
        if (!this.error) {
          this.successMessage = this.$t("general.successfully_updated");
          this.getGiftHistoryResult()
        } else this.errorMessages.push(this.error)
      })
    },
    sortOrder(orderBy, sort) {
      let self = this;
      self.page = 1;
      self.filter.orderBy = orderBy;
      self.filter.sort = sort;

      this.getGiftHistoryResult();
    },
    setPage(page) {
      let self = this;
      self.page = page;
      this.getGiftHistoryResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    if (!this.$isGiftVisible) {
      this.$router.push({ name: 'company.dashboard' })
    }
    this.getGiftHistoryResult();
  },
  beforeDestroy() {
    const modal = UIkit.modal("#giftRequestDetails");
    if (modal) modal.$destroy(true);
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getGiftHistoryResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
